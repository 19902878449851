export const useProductsPage = () => {
  /**
   * useRoute().query.keywords から、検索用テキストボックスに表示するキーワードを作成
   * @param route useRoute した変数
   */
  const buildKeywordFromRouteQuery = (route: ReturnType<typeof useRoute>) => {
    const keywords = route.query.keywords?.toString() ?? ""

    // "aaa,bbb,ccc" -> "aaa bbb ccc"
    return keywords.split(",").join(" ")
  }

  /**
   * /products のクエリパラメーターを作成＆遷移する
   * @param keyword 検索キーワード -> PublicProductAllSearchQueryVariables の keywords に分解する
   */
  const navigateToProductsPage = async (keyword: string) => {
    const queryKeywords = keyword
      // eslint-disable-next-line no-irregular-whitespace
      .split(/ |　/)
      .filter((keyword) => keyword !== "")
      .join(",")
    await navigateTo({
      path: "/products",
      query: {
        keywords: queryKeywords,
      },
    })
  }

  return { buildKeywordFromRouteQuery, navigateToProductsPage }
}
